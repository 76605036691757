.form{
  margin: 60px;
  border-radius: 30px;
  border: none;
  box-shadow: 2px 5px 8px 2px rgba(0,0,0,0.2);
}
#UMFB-Logo{
  width: 500px;
  margin: 20px;
}
h1{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.note{
  padding: 15px;
  text-align: left;
  margin-left: 40px;
}
.red-asteriks{
  color: red;
}
label{
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: 700;
}
/* learn more on account type */
div>span{
  text-decoration: none;
  color: green;
  font-size: 12px;
  cursor: pointer;
}
/* for both page sections */
.page-section{
  margin: 20px 0px;
}
/* container wrapping input/select and label */
.item-container{
  display: inline-block;
  margin: 15px;
  text-align: left;
}
/* all input */
.entries{
  width: 290px;
  height: 40px;
  padding: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 16px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: rgb(240, 239, 239);
}
/* all select */
.list{
  width: 310px;
  padding: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 16px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: rgb(240, 239, 239);
}
.long{
  width: 1000px;
}
.mid{
  width: 650px;
}
option{
  padding: 10px;
  height: 10px;
  margin: 20px;
  line-height: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.img-container{
  display: flex;
  padding: 0px 14px;
}
.img-input{
  width: 205px;
  height: 40px;
  padding: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 16px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: rgb(240, 239, 239);
  cursor: pointer;
}
.image{
  width: 180px;
  padding: 10px 10px;
  margin-left: 60px;
  font-size: 15px;
  text-align: left;
}
.no-image{
  height: 150px;
  color: rgb(120, 120, 120);
}
.picture-box{
  display: inline-block;
  height: 150px;
  color: lightgray;
  line-height: 150px;
  text-align: center;
  border: 0.5px solid gray;
  font-weight: 600;
  font-size: 15px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.select-image{
  display: inline-block;
  color: black;
  font-size: 15px;
}
/* section head */
.section-head{
  width: 30%;
  padding: 15px 20px;
  border-radius: 25px;
  height: 20px;
  background-color: maroon;
  color: white;
  font-weight: 400;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 20px;
  text-align: center;
}
.section-head-hr{
  display: flex;
  justify-content: space-around;
}
.hr-cont{
  padding: 15px 0px;
  width: 30%;
}
#spouse-address{
  width: 970px;
  height: 30px;
  padding: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 16px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: rgb(240, 239, 239);
}
/* submit button */
#submit{
  background-color: rgb(115, 115, 115);
  color: white;
  font-weight: 600;
  font-size: 20px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  padding: 10px 50px;
  margin: 20px;
  border-radius: 3px;
  border: none;
}
#submit:hover{
  cursor: pointer;
  background-color: maroon;
}
.footer-text{
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 15px;
  margin: 20px 0px;
}
/* Modal container */
.modal {
  display: none;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* overflow: auto; */
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px 10px;
}

/* Modal content */
.modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  height: 80%;
  border-radius: 10px;
  max-width: 500px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}
.account-type-info{
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  overflow: auto;
  height: 85%;
  margin: 10px;
} 
.acct-type-text{
  text-align: justify;
}
.success-img{
  width: 100px;
}
.success-txt{
  color: green;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-weight: 600;
  font-size: 13px;
}
.success{
  height: 120px;
  width: 240px;
  animation: slideInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes slideInAnimation{
  from{
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to{
    transform: translate3d(0, 0, 0);
  }
}
/* Close button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/*Reshapes page when device width reduces e.g Phone Device width*/
@media (max-width: 880px){
  h1{
    font-size: 20px;
    width: 80%;
  }
  .form{
    margin: 10px;
  }
  #UMFB-Logo{
    width: 220px;
    margin: 10px;
  }
  .note{
    margin: 10px;
  }
  .section-head{
    width: 75%;
  }
  .long, .mid{
    width: 310px;
  }
  .hr-cont{
    padding: 0px;
    width: 0px;
  }
  .section-head{
   font-size: 17px;
  }
  #images-subsection{
    display: flex;
    margin: 5px;
  }
  .img-input-container{
    display: flex;
    flex-direction: column;
  }
  .img-item{
    width: 140px;
    height: 160px;
    left: 0px;
  }
  .img-input{
    width: 120px;
    height: 20px;
    font-size: 11px;
  }
  .img-container{
    display: inline-flex;
    flex-direction: column;
    padding: 0px 0px;
  }
  .image{
    width: 150px;
    padding: 2px;
    margin: 15px 0px;
  }
  .no-image{
    width: 150px;
  }
  #spouse-address{
    width: 290px;
  }
  .modal-content {
  width: 80%;
  }
}
